import { Modal, notification } from "antd";
import React, { useEffect } from "react";
import { Security, useOktaAuth } from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";
import Routes from "./components/Routes";
import axios from "axios";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "./components/AppContext";
import Router from "./components/Router";
import { ModalCommanderFormRequest, ModalCommanderRenderMount, RequestedForm } from "./components/ModalCommander";
import { AccessRequestInformation } from "./api/models/AccessRequests";
import { AuthContext, AuthContextApi } from "./components/AuthContext";
import { GroupInformation } from "./api/models/GroupInformation";
import { OktaClientId, OktaIssuer, OktaScopes } from "./config";


interface ApplicationSecurityContextEnhancerProps {
  setDialogRequest: (request: ModalCommanderFormRequest | undefined) => void;
  dialogRequest?: ModalCommanderFormRequest;
}

interface ApplicationRouterProps extends ApplicationSecurityContextEnhancerProps {
  oktaAuth: OktaAuth;
}

const ApplicationSecurityContextEnhancer: React.FC<ApplicationSecurityContextEnhancerProps> = (props) => {
  const { authState } = useOktaAuth();
  const [authContext, setAuthContext] = React.useState<AuthContextApi>({
    userId: '',
    userEmail: '',
    userName: '',
  });

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const { idToken } = authState;
      setAuthContext({
        userId: idToken?.claims.sub!,
        userEmail: idToken?.claims.email!,
        userName: idToken?.claims.name!,
      });
    }
  }, [authState])

  return (
    <AuthContext.Provider value={authContext}>
      <ModalCommanderRenderMount
        onDialogClose={() => props.setDialogRequest(undefined)}
        request={props.dialogRequest}
      />
      <RouterProvider router={Router} />
    </AuthContext.Provider>
  );
}

const ApplicationRouter: React.FC<ApplicationRouterProps> = (props) => {
  const restoreOriginalUri = React.useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
    Router.navigate(originalUri, { replace: true });
  }, []);

  return (
    <Security
      oktaAuth={props.oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ApplicationSecurityContextEnhancer {...props} />
    </Security>
  )
}

function Application() {
  const [notificationsApi, notificationContextHolder] = notification.useNotification();
  const [modalProvider, modalContextHolder] = Modal.useModal();
  const [dialogRequest, setDialogRequest] = React.useState<ModalCommanderFormRequest | undefined>();

  const queryClient = new QueryClient();
  const oktaAuth = React.useMemo(() => new OktaAuth({
    issuer: OktaIssuer,
    clientId: OktaClientId,
    redirectUri: window.location.origin + Routes.LoginCallback,
    scopes: OktaScopes,
    pkce: true
  }), []);

  // Set axios defaults
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  const appContext = React.useMemo(() => ({
    notifications: notificationsApi,
    modal: modalProvider,
    setDialogRequest: (v: ModalCommanderFormRequest | undefined) => setDialogRequest(v),
    commander: {
      requestGroupAccess: (group?: GroupInformation) => {
        setDialogRequest({
          form: RequestedForm.RequestGroupAccess,
          group: group,
        });
      },
      cancelRequest: (requestInformation: AccessRequestInformation) => {
        setDialogRequest({
          form: RequestedForm.CancelRequest,
          requestInformation: requestInformation,
        });
      },
      approveRequest: (requestInformation: AccessRequestInformation, reason?: string) => {
        setDialogRequest({
          form: RequestedForm.ApproveRequest,
          requestInformation: requestInformation,
          reason: reason,
        });
      },
      denyRequest: (requestInformation: AccessRequestInformation, reason?: string) => {
        setDialogRequest({
          form: RequestedForm.DenyRequest,
          requestInformation: requestInformation,
          reason: reason,
        });
      },
    },
  }), [notificationsApi, modalProvider, setDialogRequest]);


  return (
    <div>
      {notificationContextHolder}
      {modalContextHolder}
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appContext}>
          <ApplicationRouter
            oktaAuth={oktaAuth}
            setDialogRequest={setDialogRequest}
            dialogRequest={dialogRequest}
          />
        </AppContext.Provider>
      </QueryClientProvider>
    </div>
  )
}

export default Application;
