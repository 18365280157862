import { ModalStaticFunctions } from "antd/es/modal/confirm";
import { NotificationInstance } from "antd/es/notification/interface";
import { createContext } from "react";
import { ModalCommander, ModalCommanderFormRequest } from "./ModalCommander";
import { AccessRequestInformation } from "../api/models/AccessRequests";
import { GroupInformation } from "../api/models/GroupInformation";


/**
 * App context API
 */
export interface AppContextApi {
    notifications: NotificationInstance;
    modal: Omit<ModalStaticFunctions, 'warn'>;
    commander: ModalCommander;
    setDialogRequest: (request: ModalCommanderFormRequest | undefined) => void;
    dialogRequest?: ModalCommanderFormRequest;
}

/**
 * App context (global state)
 */
export const AppContext = createContext<AppContextApi>({
    notifications: {} as NotificationInstance,
    modal: {} as Omit<ModalStaticFunctions, 'warn'>,
    commander: {
        requestGroupAccess: (group?: GroupInformation) => {},
        cancelRequest: (requestInformation: AccessRequestInformation) => {},
        approveRequest: (requestInformation: AccessRequestInformation, reason?: string) => {},
        denyRequest: (requestInformation: AccessRequestInformation, reason?: string) => {},
    },
    setDialogRequest: (request: ModalCommanderFormRequest | undefined) => {},
    dialogRequest: undefined,
});