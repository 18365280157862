import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, Input, List, Skeleton, Tooltip, Typography } from "antd"
import { CheckSquareOutlined, ClockCircleOutlined, CloseSquareOutlined, CrownOutlined, PlusSquareOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { GroupInformation } from "../api/models/GroupInformation";
import HumanizeDuration from "humanize-duration";
import { AppContext } from "./AppContext";
import React from "react";

/**
 * Properties for AllGroupsContent component.
 * 
 * Is used to configure AllGroupsContent UI
 */
export interface HomePageConAllGroupsContentProps {
    /**
     * Is groups loading?
     */
    groupsLoading?: boolean;

    /**
     * Callback for search
     * @param search search string
     * @returns void
     */
    onSearch: (search: string) => void;

    /**
     * Current search string
     */
    currentSearch?: string;

    /**
     * Callback for filter only favorites
     * @param onlyFavorites filter only favorites (true) or all groups (false)
     * @returns void
     */
    onFilterOnlyFavorites: (onlyFavorites: boolean) => void;

    /**
     * Shows is only favorites filter is enabled
     */
    onlyFavorites?: boolean;

    /**
     * Update favorite status
     */
    onUpdateFavoriteStatus: (group: GroupInformation, isFavorite: boolean) => void;

    /**
     * Groups to show
     */
    groups?: GroupInformation[];

    /**
     * Shows is more groups available
     */
    moreGroupsAvailable?: boolean;

    /**
     * Callback for load more groups
     * @returns void
     */
    onLoadMoreGroups?: () => void;
}


/**
 * Content for all groups information. Should be visualized as a part of Card.
 * @param props properties
 * @returns component
 */
const AllGroupsContent: React.FC<HomePageConAllGroupsContentProps> = (props) => {
    const appContext = React.useContext(AppContext);

    const style = useEmotionCss(({ token }) => ({
        '.all-groups-filter__search': {
            marginBottom: token.paddingSM,
        },
        '.no-button': {
            padding: 0,
        }
    }));

    const getGroupActions = useCallback((group: GroupInformation): React.ReactNode[] | undefined => {
        let ret: React.ReactNode[] = [];

        if (group.hasPermissionsToApprove === true) {
            ret.push(
                <Tooltip title={`You can approve requests for this group`}>
                    <CrownOutlined />
                </Tooltip>
            );
        }

        if (group.accessTimeLimitMinutes !== undefined && group.accessTimeLimitMinutes !== null) {
            ret.push(
                <Tooltip title={`Access time limit: ${HumanizeDuration(group.accessTimeLimitMinutes * 60 * 1000)}`}>
                    <ClockCircleOutlined style={{color: 'red'}} />
                </Tooltip>
            );
        }

        if (group.isPartOfGroup === true) {
            ret.push(
                <Tooltip title={`You already participate in this group`}>
                    <CheckSquareOutlined style={{color: 'green'}} />
                </Tooltip>
            );
        } else {
            if (group.approvalGroups !== undefined && group.approvalGroups !== null && group.approvalGroups.length > 0) {
                ret.push(
                    <Button
                        type="link" size="small" className="no-button"
                        title="Request access"
                        onClick={() => appContext.commander.requestGroupAccess(group)}
                    >
                        <PlusSquareOutlined />
                    </Button>
                );
            } else {
                ret.push(
                    <Tooltip title={`Group can not be requested here. Please reach out to IT team directly`}>
                        <CloseSquareOutlined />
                    </Tooltip>
                );
            }
        }

        if (group.isFavorite === true) {
            ret.push(
                <Button
                    type="link" size="small" className="no-button"
                    title="Remove from favorites"
                    onClick={() => props.onUpdateFavoriteStatus(group, false)}
                >
                    <StarFilled />
                </Button>
            );
        } else {
            ret.push(
                <Button
                    type="link" size="small" className="no-button"
                    title="Add to favorites"
                    onClick={() => props.onUpdateFavoriteStatus(group, true)}
                >
                    <StarOutlined />
                </Button>
            );
        }

        return ret;
    }, []);

    const getDescription = useCallback((group: GroupInformation): React.ReactNode => {
        return <div>
            {group.description !== undefined && group.description !== null && group.description.length > 0 ? (
                <Typography.Text type="secondary">
                    {group.description}
                </Typography.Text>
            ) : null}
        </div>
    }, []);

    if (props.groupsLoading === true) {
        return <Skeleton active={true} title={false} />;
    }

    return <div className={style}>
        {props.onlyFavorites !== true && (
            <Input.Search
                placeholder="Search for groups"
                allowClear
                className="all-groups-filter__search"
                enterButton="Search"
                size="large"
                onSearch={props.onSearch}
                defaultValue={props.currentSearch}
            />
        )}

        <List
            itemLayout="horizontal"
            size='small'
            dataSource={props.groups}
            loadMore={
                props.moreGroupsAvailable === true && props.onLoadMoreGroups !== undefined ? (
                    <Button
                        type="link"
                        onClick={props.onLoadMoreGroups}
                        block
                    >
                        Load more
                    </Button>
                ) : undefined
            }
            renderItem={(item: GroupInformation, index) => (
                <List.Item
                    actions={getGroupActions(item)}
                >
                    <List.Item.Meta
                        title={item.name}
                        description={getDescription(item)}
                    />
                </List.Item>
            )}
        />
    </div>
};


export default AllGroupsContent;
