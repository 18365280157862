import React from "react";
import axios from "axios";

import { AccessRequestInformation } from "../api/models/AccessRequests";
import MyApprovalsPageContent from "../components/MyApprovalsPageContent";
import { usePageTitle } from "../components/PageTitle";
import { useInfiniteQuery } from "@tanstack/react-query";
import Paginated from "../api/models/Paginated";


/**
 * Page with details about the request
 * @returns 
 */
const MyApprovalsPage: React.FC = () => {
    usePageTitle('My Approvals');

    const approvals = useInfiniteQuery<Paginated<AccessRequestInformation>>({
        queryKey: [
            'MyApprovalsPage__MyApprovals'
        ],
        queryFn: async ({
            pageParam = 0,
        }) => {
            return (
                await axios.get('/api/requests', {
                    params: {
                        after: pageParam,
                    }
                })
            ).data;
        },
        getNextPageParam: (lastPage, _) => lastPage.nextCursor,
    })
    const allApprovals = approvals.data?.pages.flatMap(x => x.data) ?? [];
    
    return <MyApprovalsPageContent
        loading={approvals.isLoading}
        myApprovals={allApprovals}
        moreMyApprovalsAvailable={approvals.hasNextPage}
        onLoadMoreMyApprovals={approvals.fetchNextPage}
    />
}

export default MyApprovalsPage;
