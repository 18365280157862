import { useEmotionCss } from '@ant-design/use-emotion-css';
import Lottie from 'react-lottie';
import * as animationData from './SecurityAnimation.json'
import { useEffect, useState } from 'react';

export interface AuthenticatingProps {
    fullHeight?: boolean
}

export const Authenticating: React.FC<AuthenticatingProps> = (props) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const [takingLong, setTakingLong] = useState(false);

    // 100ms delayed animation
    useEffect(() => {
        const timeout = setTimeout(() => {
            setStartAnimation(true);
        }, 300);

        return () => clearTimeout(timeout);
    })

    // 5s delayed taking long

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTakingLong(true);
        }, 5000);

        return () => clearTimeout(timeout);
    })
    
    const style = useEmotionCss(({ token }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: props.fullHeight === true ? '100vh' : '100%',

        '& > .inner': {
            width: '60vw',
            height: '60vh',
        },

        'h2': {
            textAlign: 'center',
            textTransform: 'uppercase',
        },

        'h3': {
            textAlign: 'center',
        }
    }));

    return <div className={style}>
        <div className='inner'>
            <Lottie
                isPaused={!startAnimation}
                isStopped={!startAnimation}
                style={{ visibility: startAnimation ? 'visible' : 'hidden' }}
                speed={0.6}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid meet'
                    }
                }}
            />
            <h2>
                Authenticating...
            </h2>
            {takingLong && (
                <h3>
                    Please wait while we authenticate you with your identity provider. <br />
                    If this takes too long, please contact IT team.
                </h3>
            )}
        </div>
    </div>
}