import { Card } from "antd"

import MyApprovalsContent, { MyApprovalsContentProps } from "./MyApprovalsContent";

export interface MyRequestsPageContentProps extends MyApprovalsContentProps {

}


const MyApprovalsPageContent: React.FC<MyRequestsPageContentProps> = (props) => {

    return <div>
        <Card
            title="History of my approvals"
        >
            <MyApprovalsContent {...props} />
        </Card>
    </div>;
}

export default MyApprovalsPageContent;