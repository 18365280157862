import axios from "axios";
import React, { useContext, useEffect } from "react";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import HomePageContent from "../components/HomePageContent";
import { AccessRequestInformation } from "../api/models/AccessRequests";
import { usePageTitle } from "../components/PageTitle";
import Paginated from "../api/models/Paginated";
import { GroupInformation } from "../api/models/GroupInformation";
import { AppContext } from "../components/AppContext";
import { useSearchParams } from "react-router-dom";


/**
 * Page with details about the request
 * @returns 
 */
const HomePage: React.FC = () => {
    usePageTitle('Dashboard');
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState<string | undefined>(
        () => searchParams.get('search') ?? undefined
    );
    const [onlyFavorites, setOnlyFavorites] = React.useState<boolean>(
       () => searchParams.get('onlyFavorites') === '1'
    );

    useEffect(() => {
        let params = {} as Record<string, string>;
        if (search && search !== undefined && search !== null) {
            params['search'] = search;
        }
        if (onlyFavorites) {
            params['onlyFavorites'] = '1';
        }
        setSearchParams(params);
    }, [search, onlyFavorites]);

    const appCtx = useContext(AppContext);

    const groups = useInfiniteQuery<Paginated<GroupInformation>>({
        queryKey: [
            'HomePage__Groups', search, onlyFavorites
        ],
        queryFn: async ({
            pageParam = 0,
        }) => {
            return (
                await axios.get('/api/groups', {
                    params: {
                        query: search,
                        onlyFavorite: onlyFavorites ? 1 : 0,
                        after: pageParam,
                    }
                })
            ).data;
        },
        getNextPageParam: (lastPage, _) => lastPage.nextCursor,
    })
    const allGroups = groups.data?.pages.flatMap(x => x.data) ?? [];

    const myRequests = useQuery<Paginated<AccessRequestInformation>>({
        queryKey: [
            'HomePage__MyRequests'
        ],
        queryFn: async () => {
            return (
                await axios.get('/api/requests', { params: { limit: 5} })
            ).data;
        }
    });

    const myApprovals = useQuery<Paginated<AccessRequestInformation>>({
        queryKey: [
            'HomePage__MyApprovals'
        ],
        queryFn: async () => {
            return (
                await axios.get('/api/approve-requests', { params: { limit: 5} })
            ).data;
        }
    });

    const updateFavoriteStatus = async (group: GroupInformation, isFavorite: boolean) => {

        appCtx.notifications.info({
            message: 'Updating favorite status',
            description: isFavorite ? `Adding group ${group.name} to favorites` : `Removing group ${group.name} from favorites`,
        });

        try {
            await axios.request({
                url: `/api/groups/${group.id}/favorite`,
                method: isFavorite ? 'PUT' : 'DELETE',
            });

            groups.refetch();

            appCtx.notifications.success({
                message: 'Updated favorite status',
                description: isFavorite ? `Added group ${group.name} to favorites` : `Removed group ${group.name} from favorites`,
            });
        } catch (e) {
            appCtx.notifications.error({
                message: 'Failed to update favorite status',
                description: isFavorite ? `Failed to add group ${group.name} to favorites` : `Failed to remove group ${group.name} from favorites`,
            });
        }
    }


    return <HomePageContent
        loading={
            myRequests.isLoading || myApprovals.isLoading
        }

        groupsLoading={groups.isLoading}
        groups={allGroups}
        moreGroupsAvailable={groups.hasNextPage}
        onLoadMoreGroups={groups.fetchNextPage}
        onUpdateFavoriteStatus={updateFavoriteStatus}

        onSearch={setSearch}
        currentSearch={search}
        onFilterOnlyFavorites={setOnlyFavorites}
        onlyFavorites={onlyFavorites}

        myApprovals={myApprovals.data?.data ?? []}
        myRequests={myRequests.data?.data ?? []}
        showSeeAllButton={true}
    />
}

export default HomePage;
