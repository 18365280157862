const Routes = {
    Home: '/',
    MyRequests: '/requests',
    GroupDetails: '/groups/:id',
    Request: '/requests/:id',
    MyApprovals: '/approvals',
    LoginCallback: '/okta/authcallback',
    Authenticating: '/authenticating',
}

export const AllRoutes = Object.values(Routes);

export const RouteMapping = {
    [Routes.Request]: Routes.MyRequests,
    [Routes.GroupDetails]: Routes.Home,
};

export default Routes;