import { createContext } from "react";


/**
 * Auth context API
 */
export interface AuthContextApi {
    userId: string;
    userEmail: string;
    userName: string;
}

/**
 * Auth context (global state)
 */
export const AuthContext = createContext<AuthContextApi>({
    userId: '',
    userEmail: '',
    userName: '',
});
