import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, Empty, List, Skeleton, Typography } from "antd"
import { AccessRequestInformation, AccessRequestStatus } from "../api/models/AccessRequests";
import { CheckSquareOutlined, CloseSquareOutlined, EyeOutlined } from "@ant-design/icons";
import React from "react";

import Ago from "./Ago";
import Routes from "./Routes";
import Router from "./Router";
import { AppContext } from "./AppContext";

export interface MyApprovalsContentProps {
    /**
     * If requests are loading.
     */
    loading?: boolean;

    /**
     * List of requests.
     */
    myApprovals?: AccessRequestInformation[];

    /**
     * If more requests are available.
     */
    moreMyApprovalsAvailable?: boolean;

    /**
     * Called when user wants to load more requests.
     * @returns void
     */
    onLoadMoreMyApprovals?: () => void;

    /**
     * If show see all button.
     */
    showSeeAllButton?: boolean;
}


/**
 * Content of my approvals page.
 * @param props params
 * @returns 
 */
const MyApprovalsContent: React.FC<MyApprovalsContentProps> = (props) => {
    const appContext = React.useContext(AppContext);
    const style = useEmotionCss(({ token }) => ({
        '.request-clarification': {
            display: 'block',
            marginTop: token.paddingSM,
            borderLeft: `2px solid ${token.colorBorder}`,
            paddingLeft: token.paddingXS,
            fontStyle: 'italic',
        },
        '.no-button': {
            padding: 0.
        }
    }));

    if (props.loading === true) {
        return <Skeleton active={true} title={false} />;
    }


    if (props.myApprovals === undefined || props.myApprovals === null || props.myApprovals.length === 0) {
        return <>
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No requests pending your approval - you will be notified when there are any."
            />
        </>
    }

    const canMakeDecision = (request: AccessRequestInformation) => request.status === AccessRequestStatus.Pending && request.canMakeDecision;

    const getApprovalActions = (request: AccessRequestInformation): React.ReactNode[] => {
        let ret: React.ReactNode[] = [];

        if (canMakeDecision(request)) {
            ret.push(
                <Button
                    type="link" size="small"
                    title="Approve"
                    style={{ color: 'green' }}
                    className='no-button'
                    onClick={() => appContext.commander.approveRequest(request)}
                >
                    <CheckSquareOutlined />
                </Button>
            );
            ret.push(
                <Button
                    type="link" size="small"
                    title="Deny"
                    danger
                    className='no-button'
                    onClick={() => appContext.commander.denyRequest(request)}
                >
                    <CloseSquareOutlined />
                </Button>
            );
        }

        ret.push(
            <Button
                type="link" size="small"
                title="See details"
                className='no-button'
                onClick={() => Router.navigate(Routes.Request.replace(':id', request.id))}
            >
                <EyeOutlined />
            </Button>
        )

        return ret;
    }

    const buildTitle = (request: AccessRequestInformation) => {
        if (request.status === AccessRequestStatus.Approved) {
            return `Approved - ${request.groupName} for ${request.requestedForEmail}`;
        } else if (request.status === AccessRequestStatus.Denied) {
            return `Denied - ${request.groupName} for ${request.requestedForEmail}`;
        } else if (request.status === AccessRequestStatus.Revoked) {
            return `Revoked - ${request.groupName} for ${request.requestedForEmail}`;
        } else if (request.status === AccessRequestStatus.Pending) {
            return `Pending - ${request.groupName} for ${request.requestedForEmail}`;
        } else {
            return `${request.groupName} for ${request.requestedForEmail}`;
        }
    }

    return <>
        <List
            itemLayout="horizontal"
            size='small'
            className={style}
            dataSource={props.myApprovals}
            renderItem={(item, index) => (
                <List.Item
                    actions={getApprovalActions(item)}
                >
                    <List.Item.Meta
                        title={buildTitle(item)}
                        description={<>
                            Requested <Ago val={item.requestedAt} />
                            {item.clarification !== undefined && item.clarification.length > 0 ? (
                                <Typography.Text className='request-clarification'>
                                    {item.clarification}
                                </Typography.Text>
                            ) : null}
                        </>}
                    />
                </List.Item>
            )}
            loadMore={
                props.moreMyApprovalsAvailable === true && props.onLoadMoreMyApprovals !== undefined ? (
                    <Button
                        type="link"
                        onClick={props.onLoadMoreMyApprovals}
                        block
                    >
                        Load more
                    </Button>
                ) : undefined
            }
        />
        {props.showSeeAllButton && (
            <Button
                type="link"
                block
                size="small"
                onClick={() => Router.navigate(Routes.MyApprovals)}
            >
                See all
            </Button>
        )}
    </>
};

export default MyApprovalsContent;
