import { createBrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { LoginCallback } from "@okta/okta-react";
import MainLayout from "./MainLayout";
import HomePage from "../page/HomePage";
import { RequiredAuth } from "./RequiredAuth";
import MyRequestsPage from "../page/MyRequestsPage";
import MyApprovalsPage from "../page/MyApprovalsPage";
import GroupPage from "../page/GroupPage";
import RequestPage from "../page/RequestInformationPage";
import { Authenticating } from "./Authenticating";


const Router = createBrowserRouter([
    {
        path: Routes.LoginCallback,
        element: <LoginCallback />
    },
    {
        element: <RequiredAuth><MainLayout/></RequiredAuth>,
        children: [
            {
                path: Routes.Home,
                element: <HomePage />
            },
            {
                path: Routes.MyRequests,
                element: <MyRequestsPage />
            },
            {
                path: Routes.MyApprovals,
                element: <MyApprovalsPage />
            },
            {
                path: Routes.GroupDetails,
                element: <GroupPage />
            },
            {
                path: Routes.Request,
                element: <RequestPage />
            },
            {
                path: Routes.GroupDetails,
                element: <GroupPage />
            },
            {
                path: Routes.Authenticating,
                element: <Authenticating />
            }
        ]
    }
]);

export default Router;