
export const TitlePagePrefix = 'Hermes Cloud — ';
export const OktaScopes = ['openid', 'profile', 'email'];

// Okta preview tenant
// export const OktaClientId = '0oa1vnemhn5f724Rl0h8';
// export const OktaIssuer = 'https://brtech.oktapreview.com/oauth2/default';

// Okta production tenant
export const OktaClientId = '0oaxhxksm7dg3l4e1357';
export const OktaIssuer = 'https://brtech.okta.com/oauth2/default';
