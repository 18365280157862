import { Alert, Form, Input, Modal, Typography } from "antd";
import { DebounceSelect } from "./DebounceSelect";
import { useContext, useEffect, useState } from "react";
import { AccessRequestInformation } from "../api/models/AccessRequests";
import axios from "axios";
import Paginated from "../api/models/Paginated";
import { GroupInformation } from "../api/models/GroupInformation";
import React from "react";
import { AuthContext } from "./AuthContext";
import { UserInformation } from "../api/models/UserInformation";
import { AppContext } from "./AppContext";
import Router from "./Router";
import Routes from "./Routes";

export enum RequestedForm {
    RequestGroupAccess = 'RequestGroupAccess',
    CancelRequest = 'CancelRequest',
    ApproveRequest = 'ApproveRequest',
    DenyRequest = 'DenyRequest',
}

export interface ModalCommanderFormRequest {
    form: RequestedForm;
    requestInformation?: AccessRequestInformation;
    reason?: string;
    group?: GroupInformation;
}

export interface ModalCommanderRenderMountProps {
    request?: ModalCommanderFormRequest;
    onDialogClose: () => void;
}


interface ModalRequestAccessData {
    groupId: string;
    clarification: string;
    requestedFor: string;
}

export const ModalRequestAccess: React.FC<ModalCommanderRenderMountProps> = (props) => {
    const [form] = Form.useForm<ModalRequestAccessData>();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const authContext = React.useContext(AuthContext);
    const appCtx = useContext(AppContext);

    const submit = (values: ModalRequestAccessData) => {
        setConfirmLoading(true);

        let requestedFor = values.requestedFor;
        // If dict - extract id
        if (typeof values.requestedFor === 'object') {
            requestedFor = (values.requestedFor as any).value;
        }

        let groupId = values.groupId;
        // If dict - extract id
        if (typeof values.groupId === 'object') {
            groupId = (values.groupId as any).value;
        }

        axios.post<AccessRequestInformation>('/api/requests', {
            groupId: groupId,
            requestedFor: requestedFor,
            clarification: values.clarification,
            action: 'add-user',
        }).then(req => {
            appCtx.notifications.success({
                message: 'Request submitted',
                description: `Your request has been submitted. You will be notified when a decision has been made.`,
            });
            props.onDialogClose();
            Router.navigate(Routes.Request.replace(':id', req.data.id));
            window.location.reload();
        }).catch(err => {
            const error = err.response.data.error ?? `Your request failed to submit. Please try again later.`;
            appCtx.notifications.error({
                message: 'Request failed',
                description: error,
            });
        }).finally(() => {
            setConfirmLoading(false);
        })

    };

    useEffect(() => {
        form.setFieldsValue({
            clarification: props.request?.reason,
            groupId: props.request?.group !== undefined ? props.request?.group?.id : undefined,
            requestedFor: props.request?.requestInformation?.id ?? authContext.userId,
        })
    }, [props.request]);


    const buildGroupTitleForDropdown = (group: GroupInformation): string => {
        let additionalDetails = [];
        if (group.isPartOfGroup) {
            additionalDetails.push('you are already a member of this group');
        }
        let suffix = '';
        if (additionalDetails.length > 0) {
            suffix = ` (${additionalDetails.join(', ')})`;
        }
        return `${group.name}${suffix}`;
    }

    const buildUserTitleForDropdown = (user: UserInformation): string => {
        let suffix = '';
        if (user.id === authContext.userId) {
            suffix = ' [you]';
        }
        return `${user.fullName} (${user.email})${suffix}`;
    }

    return <Modal
        open={props.request?.form === RequestedForm.RequestGroupAccess}
        title="Request Group Access"
        onCancel={props.onDialogClose}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        width={'80vw'}
    >
        <Form
            form={form}
            layout="vertical"
            name="request-access"
            onFinish={submit}
            initialValues={{
                groupId: props.request?.group !== undefined ? {
                    value: props.request?.group?.id,
                    label: buildGroupTitleForDropdown(props.request?.group),
                } : undefined,
                clarification: props.request?.reason,
                requestedFor: {
                    value: authContext.userId,
                    label: buildUserTitleForDropdown({
                        id: authContext.userId,
                        email: authContext.userEmail,
                        fullName: authContext.userName,
                    }),
                }
            }}
        >
            <Form.Item
                name="requestedFor"
                label="Requesting for"
                rules={[{
                    required: true,
                    message: 'Please select a user you are requesting access for.',
                }]}
            >
                <DebounceSelect
                    placeholder="For myself (use this field to select another user)"
                    initialOptions={[
                        {
                            label: buildUserTitleForDropdown({
                                id: authContext.userId,
                                email: authContext.userEmail,
                                fullName: authContext.userName,
                            }),
                            value: authContext.userId,
                        }
                    ]}
                    labelInValue={true}
                    fetchOptions={async (search: string) => {
                        const ret = await axios.get<Paginated<UserInformation>>('/api/users', {
                            params: {
                                query: search,
                            }
                        });

                        const data = ret.data.data.map(x => ({
                            label: buildUserTitleForDropdown(x),
                            value: x.id,
                        }));

                        return data;
                    }}
                />
            </Form.Item>
            <Form.Item
                name="groupId"
                label="Group ID"
                rules={[{
                    required: true,
                    message: 'Please select a group you are requesting access to.',
                }]}
            >
                <DebounceSelect
                    placeholder="Search for a group"
                    initialOptions={(props.request?.group !== null && props.request?.group !== undefined) ? [
                        {
                            label: buildGroupTitleForDropdown(props.request?.group),
                            value: props.request?.group.id,
                        }
                    ] : []}
                    labelInValue={true}
                    fetchOptions={async (search: string) => {
                        const ret = await axios.get<Paginated<GroupInformation>>('/api/groups', {
                            params: {
                                query: search,
                            }
                        });

                        return ret.data.data.map(x => ({
                            label: buildGroupTitleForDropdown(x),
                            value: x.id,
                        }));
                    }}
                />
            </Form.Item>

            <Form.Item
                name="clarification"
                label="Clarification"
                rules={[
                    { max: 500 },
                    {
                        required: true,
                        message: 'Please provide a clarification for your request.',
                    }
                ]}
            >
                <Input.TextArea
                    showCount
                    maxLength={500}
                />
            </Form.Item>
        </Form>
    </Modal>;
}




interface ModalDecisionData {
    clarification: string;
}

export const ModalDecision: React.FC<ModalCommanderRenderMountProps> = (props) => {
    const [form] = Form.useForm<ModalDecisionData>();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const appCtx = useContext(AppContext);

    useEffect(() => {
        form.setFieldsValue({
            clarification: props.request?.reason,
        })
    }, [props.request]);

    const isApproval = props.request?.form === RequestedForm.ApproveRequest;
    const isDenial = props.request?.form === RequestedForm.DenyRequest;
    const command = isApproval ? 'approve' : 'deny';

    const submit = (values: ModalDecisionData) => {
        setConfirmLoading(true);

        if (props.request === undefined || props.request === null || props.request.requestInformation === undefined || props.request.requestInformation === null) {
            setConfirmLoading(false);
            return;
        }

        axios.post<AccessRequestInformation>(`/api/requests/${props.request.requestInformation.id}/decision`, {
            decision: isApproval ? 'approve' : 'deny',
            clarification: values.clarification,
        }).then(req => {
            appCtx.notifications.success({
                message: 'Decision submitted',
                description: `Your decision has been submitted, user will get notification soon.`,
            });
            props.onDialogClose();
            Router.navigate(Routes.Request.replace(':id', req.data.id));
        }).catch(err => {
            const error = err.response.data.error ?? `Your decision failed to submit. Please try again later.`;
            appCtx.notifications.error({
                message: 'Decision submission failed',
                description: error,
            });
        }).finally(() => {
            setConfirmLoading(false);
        })
    };

    return <Modal
        open={props.request?.form !== undefined && (isApproval || isDenial)}
        title={isApproval ? 'Approve Request' : 'Deny Request'}
        onCancel={props.onDialogClose}
        onOk={form.submit}
        okType="danger"
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        width={'80vw'}
        okText={isApproval ? 'Approve' : 'Deny'}
    >
        <Alert
            message={
                <>
                    Are you sure you want to <b>{command}</b> this request?
                </>
            }
            description={
                <Typography.Text>
                    Addition of user {props.request?.requestInformation?.requestedByEmail} to {props.request?.requestInformation?.groupName}
                </Typography.Text>
            }
            type="warning"
            style={{ marginBottom: '20px' }}
        />

        <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            name="request-access"
        >
            <Form.Item
                name="clarification"
                label="Decision clarification"
                rules={[
                    { max: 500 }
                ]}
            >
                <Input.TextArea
                    showCount
                    maxLength={500}
                />
            </Form.Item>
        </Form>
    </Modal>;
}

// We can make it through Modal. but we do the same to have same interface
export const ModalCancelRequest: React.FC<ModalCommanderRenderMountProps> = (props) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const appCtx = useContext(AppContext);

    const onCancel = () => {
        setConfirmLoading(true);

        axios.delete<AccessRequestInformation>(`/api/requests/${props.request?.requestInformation?.id}`).then(_ => {
            appCtx.notifications.success({
                message: 'Request cancelled',
                description: `Your request has been cancelled.`,
            });
            props.onDialogClose();
            Router.navigate(Routes.Home);
        }).catch(err => {
            const error = err.response.data.error ?? `Your request failed to cancel. Please try again later.`;
            appCtx.notifications.error({
                message: 'Cancellation failed',
                description: error,
            });
            props.onDialogClose();
        }).finally(() => {
            setConfirmLoading(false);
        })
    }

    return <Modal
        open={props.request?.form === RequestedForm.CancelRequest}
        title="Cancel Request"
        onCancel={props.onDialogClose}
        onOk={onCancel}
        okType="danger"
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        width={'80vw'}
        cancelText=''
        okText="Yes, cancel my request"
    >
        <Alert
            message={
                <>
                    Are you sure you want to cancel your request?
                </>
            }
            description={
                <div>
                    <Typography.Paragraph>
                        Addition of user {props.request?.requestInformation?.requestedByEmail} to {props.request?.requestInformation?.groupName}
                    </Typography.Paragraph>
                    {props.request?.requestInformation?.clarification !== undefined && props.request?.requestInformation?.clarification !== null && (
                        <Typography.Text>
                            Clarification: <i>{props.request?.requestInformation?.clarification}</i>
                        </Typography.Text>
                    )}
                </div>
            }
            type="warning"
            style={{ marginBottom: '20px' }}
        />
    </Modal>
}

export const ModalCommanderRenderMount: React.FC<ModalCommanderRenderMountProps> = (props) => {
    return <div>
        <ModalRequestAccess {...props} />
        <ModalDecision {...props} />
        <ModalCancelRequest {...props} />
    </div>
}


export interface ModalCommander {
    requestGroupAccess: (group?: GroupInformation) => void;

    cancelRequest: (requestInformation: AccessRequestInformation) => void;

    approveRequest: (requestInformation: AccessRequestInformation, reason?: string) => void;
    denyRequest: (requestInformation: AccessRequestInformation, reason?: string) => void;
}

