import { Card, Col, Row, Space, Switch } from "antd"
import { StarFilled, StarOutlined } from "@ant-design/icons";

import MyRequestsContent, { MyRequestsContentProps } from "./MyRequestsContent";
import MyApprovalsContent, { MyApprovalsContentProps } from "./MyApprovalsContent";
import AllGroupsContent, { HomePageConAllGroupsContentProps } from "./AllGroupsContent";
import Routes from "./Routes";
import { Link } from "react-router-dom";

/**
 * Properties for HomePageContent component.
 */
export interface HomePageContentProps 
extends 
    MyRequestsContentProps, 
    MyApprovalsContentProps, 
    HomePageConAllGroupsContentProps {
}

/**
 * Content for home page
 * @param props properties
 * @returns component
 */
const HomePageContent: React.FC<HomePageContentProps> = (props) => {

    let allGroupsTitle = 'All Groups';
    if (props.onlyFavorites === true){
        allGroupsTitle = 'Favorite Groups';
    } else if (props.currentSearch !== undefined && props.currentSearch !== ''){
        allGroupsTitle = `Search results for "${props.currentSearch}"`;
    }

    return <div>
        <Row className="home-page__row" gutter={[16, 16]}>
            <Col span={14}>
                <Card
                    title={allGroupsTitle}
                    extra={
                        <Switch
                            checkedChildren={<StarFilled />}
                            unCheckedChildren={<StarOutlined />}
                            checked={props.onlyFavorites}
                            onChange={props.onFilterOnlyFavorites}
                        />
                    }
                >
                    <AllGroupsContent {...props} />
                </Card>
            </Col>
            <Col span={10}>
                <Space
                    size={16}
                    direction="vertical"
                    style={{ width: '100%' }}
                >
                    <Card
                        size="small"
                        title="My Requests"
                        extra={<Link to={Routes.MyRequests}>See history</Link>}
                    >
                        <MyRequestsContent {...props} />
                    </Card>
                    <Card
                        size="small"
                        title="My Approvals"
                        extra={<Link to={Routes.MyApprovals}>See history</Link>}
                    >
                        <MyApprovalsContent {...props} />
                    </Card>
                </Space>
            </Col>
        </Row>
    </div>;
}

export default HomePageContent;