export enum AccessRequestStatus {
    Pending = "pending",
    Approved = "approved",
    Denied = "denied",
    Revoked = "revoked"
}

export enum AccessRequestAction {
    AddUser = "add-user",
}


export interface AccessRequestInformation {
    id: string;
    action: AccessRequestAction;
    
    // Name of the target group
    groupId: string;
    groupName: string;
    groupDescription?: string;

    // Index
    status: AccessRequestStatus;
    
    requestedBy: string;
    requestedByEmail: string;
    requestedByName: string;
    
    requestedFor: string;
    requestedForEmail: string;
    requestedForName: string;

    requestedAt: Date;

    approvedAt?: Date;
    approvedBy?: string;
    approvedByEmail?: string;
    approvedByName?: string;

    rejectedAt?: Date;
    rejectedBy?: string;
    rejectedByEmail?: string;
    rejectedByName?: string;

    clarification?: string;
    decisionClarification?: string;

    revocationScheduledAt?: Date;
    revokedAt?: Date;

    canMakeDecision: boolean;
}

export interface AccessRequestCreationResponse {
    accessRequest: AccessRequestInformation;
}

export interface AccessRequestListResponse {
    accessRequests: AccessRequestInformation[];
    next?: string;
}