import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { Authenticating } from './Authenticating';
import axios from 'axios';


export interface RequiredAuthProps {
  children?: any;
}

export const RequiredAuth: React.FC<RequiredAuthProps> = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [authorized, setAuthorized] = React.useState<boolean>(false);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  // Update axios authorization header based on authState
  useEffect(() => {
    const token = authState?.accessToken?.accessToken;
    if (token !== undefined){
      axios.defaults.headers.common['Authorization'] = token;
      setAuthorized(true);
    }
  }, [authState?.accessToken?.accessToken])

  if (!authState || !authState?.isAuthenticated || !authorized) {
    return <Authenticating />
  }

  if (props.children !== undefined){
    return props.children;
  }

  return <Outlet />
}
