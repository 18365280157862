import { Tooltip } from "antd"
import ReactTimeAgo from "react-time-ago"
import TimeAgo from 'javascript-time-ago'
import { useContext, useEffect } from "react"
import en from 'javascript-time-ago/locale/en.json'

import { AppContext } from "./AppContext"


// Initialize the time-ago locale
TimeAgo.addDefaultLocale(en)


/**
 * Properties for Ago component
 */
export interface AgoProps {
    val: Date | string;
}


/**
 * Component to show time ago
 * @param props properties
 * @returns component
 */
const Ago: React.FC<AgoProps> = (props) => {
    const appCtx = useContext(AppContext);

    if (props.val === undefined || props.val === null){
        return null;
    }

    let value: Date = new Date();

    if (typeof props.val === "string"){
        value = new Date(props.val);
    } else {
        value = props.val;
    }
    
    const onClick = () => {
        // Copy to buffer
        navigator.clipboard.writeText(value.toISOString());
        appCtx.notifications.success({
            message: 'Copied to clipboard',
            description: 'The date has been copied to your clipboard.'
        });
    }

    let val: any = props.val;
    if (typeof val === "string"){
        val = new Date(val).getTime();
    }
    
    return (
      <ReactTimeAgo
        onClick={onClick}
        date={value}
        style={{ cursor: 'pointer' }}
        wrapperComponent={TooltipContainer}
        tooltip={false}/>
    )
}


/**
 * Properties for TooltipContainer component
 */
export interface TooltipContainerProps {
    verboseDate: string;
    children: React.ReactNode;
}

/**
 * Tooltip container for verbose date (used in Ago component)
 * @param param0 props
 * @returns component
 */
const TooltipContainer: React.FC<TooltipContainerProps> = ({ verboseDate, children }) => (
    <Tooltip 
        placement="top" 
        title={verboseDate} 
        arrow={true}
    >
        {children}
    </Tooltip>
)

export default Ago;