import { Button, Empty, List, Skeleton } from "antd"
import { AccessRequestInformation, AccessRequestStatus } from "../api/models/AccessRequests";
import { CloseSquareOutlined, EyeOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import Ago from "./Ago";
import UserReference from "./UserReference";
import { useEmotionCss } from "@ant-design/use-emotion-css";
import Routes from "./Routes";
import Router from "./Router";
import { AppContext } from "./AppContext";
import React from "react";
import { AuthContext } from "./AuthContext";


export interface MyRequestsContentProps {
    /**
     * If requests are loading.
     */
    loading?: boolean;

    /**
     * List of requests.
     */
    myRequests?: AccessRequestInformation[];

    /**
     * If more requests are available.
     */
    moreMyRequestsAvailable?: boolean;

    /**
     * Called when user wants to load more requests.
     * @returns 
     */
    onLoadMoreMyRequests?: () => void;

    /**
     * If show see all button.
     */
    showSeeAllButton?: boolean;
}


const MyRequestsContent: React.FC<MyRequestsContentProps> = (props) => {
    const appContext = React.useContext(AppContext);
    const authContext = React.useContext(AuthContext);
    const style = useEmotionCss(({ token }) => ({
        '.no-button': {
            padding: 0.
        }
    }));


    const getActions = useCallback((req: AccessRequestInformation): React.ReactNode[] => {
        let ret: React.ReactNode[] = [];

        if (req.status === AccessRequestStatus.Pending){
            ret.push(
                <Button
                    type="link" size="small"
                    title="Cancel"
                    danger
                    className='no-button'
                    onClick={() => appContext.commander.cancelRequest(req)}
                >
                    <CloseSquareOutlined />
                </Button>
            );
        }

        ret.push(
            <Button
                type="link" size="small"
                title="See details"
                onClick={() => Router.navigate(Routes.Request.replace(':id', req.id))}
                className='no-button'
            >
                <EyeOutlined />
            </Button>
        );

        return ret;
    }, []);


    const getRequestTitle = useCallback((req: AccessRequestInformation): string => {
        if (req.requestedFor !== authContext.userId) {
            return `${req.groupName} [for ${req.requestedForEmail}]`;
        }
        return req.groupName
    }, [authContext.userId]);

    const getDetails = useCallback((req: AccessRequestInformation): React.ReactNode => {
        if (req.status === AccessRequestStatus.Approved) {

            return <>
                Approved
                {(req.approvedByEmail !== undefined && req.approvedByEmail !== null) ? <>{' '} by <UserReference email={req.approvedByEmail} name={req.approvedByName} /></> : null}
                {(req.approvedAt !== undefined && req.approvedAt !== null) ? <>{' '}<Ago val={req.approvedAt} /></> : null}
                {(req.revocationScheduledAt !== undefined && req.revocationScheduledAt !== null) ? (
                    <>
                        <br />
                        Revocation scheduled <Ago val={req.revocationScheduledAt} />
                    </>
                ) : null}
            </>
        } else if (req.status === AccessRequestStatus.Denied) {
            let ret = 'Rejected';
            if (req.rejectedByName !== undefined && req.rejectedByName !== null) {
                ret += ` by ${req.rejectedByName}`;
            } else {
                ret += ` by system`
            }

            if (req.decisionClarification !== undefined && req.decisionClarification !== null && req.decisionClarification.length > 0) {
                ret += ` with reason: ${req.decisionClarification}`;
            } 

            return ret;
        } else if (req.status === AccessRequestStatus.Pending) {
            return <>
                Still pending (requested <Ago val={req.requestedAt} />)
            </>
        } else if (req.status === AccessRequestStatus.Revoked) {
            if (req.revokedAt === undefined) {
                return 'Revoked';
            }

            return <>
                Revoked <Ago val={req.revokedAt} />
            </>
        }

        return '';
    }, []);

    if (props.loading === true) {
        return <Skeleton active={true} title={false} />;
    }

    if (props.myRequests === undefined || props.myRequests.length === 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
            <Button 
                type="primary" 
                size="small"
                onClick={
                    () => appContext.commander.requestGroupAccess()
                }
            >
                Request new group
            </Button>
        </Empty>
    }

    return <>
        <List
            itemLayout="horizontal"
            size='small'
            className={style}
            dataSource={props.myRequests}
            renderItem={(item, index) => (
                <List.Item
                    actions={getActions(item)}
                >
                    <List.Item.Meta
                        title={getRequestTitle(item)}
                        description={getDetails(item)}
                    />
                </List.Item>
            )}
            loadMore={
                props.moreMyRequestsAvailable === true && props.onLoadMoreMyRequests !== undefined ? (
                    <Button
                        type="link"
                        onClick={props.onLoadMoreMyRequests}
                        block
                    >
                        Load more
                    </Button>
                ) : undefined
            }
        />

        {props.showSeeAllButton && (
            <Button
                type="link"
                block
                size="small"
                onClick={() => Router.navigate(Routes.MyRequests)}
            >
                See all
            </Button>
        )}
    </>
};

export default MyRequestsContent;
