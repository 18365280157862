import { useEffect } from "react";
import { TitlePagePrefix } from "../config";

/**
 * React hook to set the page title
 * @param title title of the page
 */
export const usePageTitle = (title: string) => {
    useEffect(() => {
        window.document.title = `${TitlePagePrefix}${title}`;
    }, [title]);
}
