import { Card } from "antd"

import MyRequestsContent, { MyRequestsContentProps } from "./MyRequestsContent";

/**
 * Properties for MyRequestsPageContent component.
 */
export interface MyRequestsPageContentProps extends MyRequestsContentProps {

}

/**
 * Content of my requests page.
 * @param props params
 */
const MyRequestsPageContent: React.FC<MyRequestsPageContentProps> = (props) => {
    return <div>
        <Card
            title="History of my requests"
        >
            <MyRequestsContent {...props} />
        </Card>
    </div>;
}

export default MyRequestsPageContent;