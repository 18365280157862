import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Badge, Button, Card, Descriptions, Divider, Skeleton, Typography } from "antd"
import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { AccessRequestInformation, AccessRequestStatus } from "../api/models/AccessRequests";
import UserReference from "./UserReference";
import Ago from "./Ago";
import { AppContext } from "./AppContext";
import React from "react";


export interface RequestInformationPageContentProps {
    isLoading?: boolean;
    request?: AccessRequestInformation;

    isMyRequest?: boolean;
    canMakeDecision?: boolean;
}

export const GetStatusDisplay = (status: AccessRequestStatus): React.ReactNode => {
    switch (status) {
        case AccessRequestStatus.Pending:
            return <Badge status="processing" text="Pending" />;
        case AccessRequestStatus.Approved:
            return <Badge status="success" text="Approved" />;
        case AccessRequestStatus.Denied:
            return <Badge status="error" text="Denied" />;
        case AccessRequestStatus.Revoked:
            return <Badge status="warning" text="Revoked" />;
        default:
            return <Badge status="default" text="Unknown" />;
    }
}

const RequestInformationPageContent: React.FC<RequestInformationPageContentProps> = (props) => {
    const appContext = React.useContext(AppContext);

    const style = useEmotionCss(({ token }) => ({
        ".actions": {
            marginTop: token.margin,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: token.paddingXL
        }
    }));

    if (props.isLoading === true || props.request === undefined || props.request == null) {
        return <div className={style}>
            <Card
                title={`Request information`}
            >
                <Skeleton active={true} title={false} />
            </Card>
        </div>
    }

    return <div className={style}>
        <Card
            title={`Request #${props.request.id}`}
        >
            <Descriptions
                layout="vertical"
                size={"middle"}
                column={{ xs: 1, sm: 2, md: 2 }}
            >
                <Descriptions.Item label="Request Status" span={2}>
                    {GetStatusDisplay(props.request.status)}
                </Descriptions.Item>
            </Descriptions>

            <Divider>Request details</Divider>
            <Descriptions
                layout="vertical"
                size={"middle"}
                column={{ xs: 1, sm: 2, md: 2 }}
            >
                <Descriptions.Item label="Requested at" span={2}>
                    <Ago val={props.request.requestedAt} />
                </Descriptions.Item>

                <Descriptions.Item label="Requested by">
                    <UserReference email={props.request.requestedByEmail} name={props.request.requestedByName} />
                </Descriptions.Item>
                <Descriptions.Item label="Requested for">
                    <UserReference email={props.request.requestedForEmail} name={props.request.requestedForName} />
                </Descriptions.Item>
                <Descriptions.Item label="Request clarification" span={2}>
                    {props.request.clarification !== undefined && props.request.clarification !== null && props.request.clarification.length > 0 ? (
                        <Typography.Text>{props.request.clarification}</Typography.Text>
                    ) : 'Not provided'}
                </Descriptions.Item>
            </Descriptions>

            <Divider>Group Details</Divider>

            <Descriptions
                layout="vertical"
                size={"middle"}
                column={{ xs: 1, sm: 2, md: 2 }}
            >
                <Descriptions.Item label="Group Name">
                    {props.request.groupName}
                </Descriptions.Item>
                <Descriptions.Item label="Group Description">
                    {props.request.groupDescription !== undefined && props.request.groupDescription !== null && props.request.groupDescription.length > 0 ? (
                        <Typography.Text>{props.request.groupDescription}</Typography.Text>
                    ) : 'Not provided'}
                </Descriptions.Item>
            </Descriptions>

            <Divider>Approval flow</Divider>

            <Descriptions
                layout="vertical"
                size={"middle"}
                column={{ xs: 1, sm: 2, md: 2 }}
            >
                {props.request.approvedAt !== undefined && props.request.approvedAt !== null && (
                    <>
                        <Descriptions.Item label="Approved at">
                            <Ago val={props.request.approvedAt} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Approved by">
                            <UserReference email={props.request.approvedByEmail} name={props.request.approvedByName} />
                        </Descriptions.Item>
                    </>
                )}

                {props.request.rejectedAt !== undefined && props.request.rejectedAt !== null && (
                    <>
                        <Descriptions.Item label="Rejected at">
                            <Ago val={props.request.rejectedAt} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Rejected by">
                            <UserReference email={props.request.rejectedByEmail} name={props.request.rejectedByName} />
                        </Descriptions.Item>
                    </>
                )}

                {((props.request.revocationScheduledAt !== undefined && props.request.revocationScheduledAt !== null) || props.request.revokedAt) && (
                    <>
                        <Descriptions.Item label="Revocation scheduled">
                            {(props.request.revocationScheduledAt !== undefined && props.request.revocationScheduledAt !== null) ? (
                                <Ago val={props.request.revocationScheduledAt} />
                            ) : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Revoked">
                            {(props.request.revokedAt !== undefined && props.request.revokedAt !== null) ? (
                                <Ago val={props.request.revokedAt} />
                            ) : 'N/A'}
                        </Descriptions.Item>
                    </>
                )}

                {props.request.decisionClarification !== undefined && props.request.decisionClarification !== null && (
                    <Descriptions.Item label="Decision clarification" span={2}>
                        {props.request.decisionClarification.length > 0 ? (
                            <Typography.Text>{props.request.decisionClarification}</Typography.Text>
                        ) : 'Not provided'}
                    </Descriptions.Item>
                )}
            </Descriptions>

            {(props.canMakeDecision === true || props.isMyRequest === true) && (
                <div className="actions">
                    {props.canMakeDecision === true && (
                        <>
                            <Button
                                title="Approve"
                                style={{ color: 'green', borderColor: 'green' }}
                                className='no-button'
                                icon={<CheckSquareOutlined />}
                                onClick={() => appContext.commander.approveRequest(props.request!)}
                            >
                                Approve
                            </Button>

                            <Button
                                title="Deny"
                                danger
                                className='no-button'
                                icon={<CloseSquareOutlined />}
                                onClick={() => appContext.commander.denyRequest(props.request!)}
                            >
                                Deny
                            </Button>
                        </>
                    )}
                    {props.isMyRequest === true && props.request.status === AccessRequestStatus.Pending && (
                        <Button
                            title="Cancel"
                            danger
                            className='no-button'
                            icon={<CloseSquareOutlined />}
                            onClick={() => appContext.commander.cancelRequest(props.request!)}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            )}
        </Card>
    </div>;
}

export default RequestInformationPageContent;