import RequestInformationPageContent from "../components/RequestInformationPageContent";
import { AccessRequestInformation, AccessRequestStatus } from "../api/models/AccessRequests";
import { usePageTitle } from "../components/PageTitle";
import { useParams } from "react-router-dom";
import Router from "../components/Router";
import Routes from "../components/Routes";
import { AppContext } from "../components/AppContext";
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "../components/AuthContext";


/**
 * Page with details about the request
 * @returns 
 */
const RequestPage: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const appContext = React.useContext(AppContext);
    const authContext = React.useContext(AuthContext);


    const requestInformation = useQuery<AccessRequestInformation>({
        queryKey: [
            'RequestPage__RequestInformation', id
        ],
        queryFn: async () => {
            return (
                await axios.get(`/api/requests/${id}`)
            ).data;
        }
    });

    useEffect(() => {
        if (id == null || id === undefined) {
            Router.navigate(Routes.Home);
            appContext.notifications.warning({
                message: 'Invalid request ID',
                description: 'The request ID provided is invalid.',
            });
        }
    }, [id, appContext.notifications]);

    usePageTitle(`Request #${id}`);

    const isMyRequest = requestInformation.data?.requestedBy === authContext.userId || requestInformation.data?.requestedFor === authContext.userId || true;
    const canMakeDecision = requestInformation.data?.status === AccessRequestStatus.Pending && requestInformation.data?.canMakeDecision;

    return <RequestInformationPageContent
        canMakeDecision={canMakeDecision}
        isMyRequest={isMyRequest}
        request={requestInformation.data}
        isLoading={requestInformation.isLoading}
    />;
}

export default RequestPage;