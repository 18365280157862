import React, { useContext } from 'react';
import type { MenuProps } from 'antd';
import { Layout, Menu, Space } from 'antd';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import { CheckSquareOutlined, HomeOutlined, PlusSquareOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Outlet, matchPath, useLocation } from 'react-router';

import Routes, { AllRoutes, RouteMapping } from './Routes';
import Router from './Router';
import { AppContext } from './AppContext';
import { AuthContext } from './AuthContext';
import { ModalCommanderRenderMount } from './ModalCommander';

const { Header, Content, Footer } = Layout;

const ACTION_REQUEST = '#action-request';

export const TopMenuItems: MenuProps['items'] = [
    {
        key: Routes.Home,
        label: 'Dashboard',
        icon: <HomeOutlined />,
    },
    {
        key: Routes.MyRequests,
        label: 'My Requests',
        icon: <UnorderedListOutlined />,
    },
    {
        key: Routes.MyApprovals,
        label: 'My Approvals',
        icon: <CheckSquareOutlined />,
    },
    {
        key: ACTION_REQUEST,
        label: 'Request new access',
        icon: <PlusSquareOutlined />,
    }
];

export interface MainLayoutProps {
    children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
    const appContext = React.useContext(AppContext);
    const authContext = React.useContext(AuthContext);
    const appCtx = useContext(AppContext);

    const username = authContext.userName || 'Account';
    const location = useLocation();


    const currentSelectedKeys = React.useMemo(() => {
        const activeRoutes = AllRoutes.filter(x => matchPath(x, location.pathname) !== null);

        let selectedKeys: string[] = [];
        for (const route of activeRoutes) {
            if (route in RouteMapping) {
                // selectedKeys.push(RouteMapping[route]);
            } else {
                selectedKeys.push(route);
            }
        }

        return selectedKeys;
    }, [location.pathname]);

    const style = useEmotionCss(({ token }) => ({
        minHeight: '100vh',

        '.main-layout__header': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff',
            flexDirection: 'row',
            
            userSelect: 'none',

            '.main-layout__header__spacer': {
                flex: 1,
            }
        },
        '.main-layout__content__holder': {
            padding: token.padding,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '.main-layout__content': {
                width: '100%',
                maxWidth: '1200px',
            },
        },
        '.main-layout__footer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: token.padding,
        }
    }));


    return (
        <Layout className={style}>
            <Header className='main-layout__header'>
                <div className="main-layout__header__logo" />
                <Menu
                    theme="light"
                    mode='horizontal'
                    selectedKeys={currentSelectedKeys}
                    items={TopMenuItems}
                    disabledOverflow={true}
                    onSelect={({ key }) => {
                        if (key === ACTION_REQUEST){
                            appContext.commander.requestGroupAccess();
                        } else {
                            Router.navigate(key);
                        }
                    }}
                />
                <div className="main-layout__header__spacer" />
                <div className="main-layout__header__info">
                    <Space>
                        {username}
                    </Space>
                </div>
            </Header>

            <Content
                className='main-layout__content__holder'
            >
                <ModalCommanderRenderMount
                    onDialogClose={() => appCtx.setDialogRequest(undefined)}
                    request={appCtx.dialogRequest}
                />
                <div className='main-layout__content'>
                    {props.children !== undefined ? (
                        <>{props.children}</>
                    ) : (
                        <Outlet />
                    )}
                </div>
            </Content>

            <Footer
                className='main-layout__footer'
            >
                <a 
                    href="https://bluerivertechnology.atlassian.net/jira/software/c/projects/IT/issues" 
                    target='_blank'
                    rel='noreferrer'
                >
                    Create IT Ticket
                </a>
                |
                <a href="mailto:it@bluerivertech.com">Contact IT</a>
            </Footer>
        </Layout>
    )
};

export default MainLayout;