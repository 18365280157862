import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Tooltip } from "antd";

export interface UserReferenceProps {
    email?: string;
    name?: string;
}

const UserReference: React.FC<UserReferenceProps> = (props) => {
    const appCtx = useContext(AppContext);

    if ((props.email === null || props.email === undefined) && (props.name === null || props.name === undefined)){
        return null;
    }

    const onClick = () => {
        if (props.email === undefined || props.email.length === 0) {
            return;
        }
        // Copy to buffer
        navigator.clipboard.writeText(props.email);
        appCtx.notifications.success({
            message: 'Copied to clipboard',
            description: 'The email address has been copied to your clipboard.'
        });
    }

    const display = props.name ?? props.email ?? 'Unknown';
    let tooltipText = props.email ? `${props.email} (click to copy)` : `Click to copy`;
    if (!props.name){
        tooltipText = '';
    }

    return (
        <Tooltip title={tooltipText}>
            <span onClick={onClick} style={{ cursor: 'pointer' }}>
                {display}
            </span>
        </Tooltip>
    )
}

export default UserReference;