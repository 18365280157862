import { Card, Descriptions } from "antd"

import { usePageTitle } from "../components/PageTitle";
import { useParams } from "react-router-dom";

/**
 * Page with details about the request
 * @returns 
 */
const GroupPage: React.FC = () => {
    const { id } = useParams<{ id?: string }>();

    usePageTitle(`Group ${id}`);
    
    return <div>
        <Card
            title={`Group information`}
        >
            <Descriptions
                layout="vertical"
                size={"middle"}
                column={{ xs: 1, sm: 2, md: 2 }}
            >
                <Descriptions.Item label="Group Name">
                    {id}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </div>;
}

export default GroupPage;